<template>
  <b-card-code title="Créer un nouveau Grade">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>

          <b-col md="12">
            <b-form-group
              label="Label"
              label-for="Label"
            >
              <validation-provider
                #default="{ errors }"
                name="Label"
                rules="required"
              >
                <b-form-input
                  v-model="grade.label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Label"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="price_ht_license"
              label-for="price_ht_license"
            >
              <validation-provider
                #default="{ errors }"
                name="price_ht_license"
                rules="required"
              >
                <b-form-input
                  v-model="grade.price_ht_license"
                  :state="errors.length > 0 ? false : null"
                  placeholder="price_ht_license"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="price_ht_engineer"
              label-for="price_ht_engineer"
            >
              <validation-provider
                #default="{ errors }"
                name="price_ht_engineer"
                rules="required"
              >
                <b-form-input
                  v-model="grade.price_ht_engineer"
                  :state="errors.length > 0 ? false : null"
                  placeholder="price_ht_engineer"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="price_ht_master"
              label-for="price_ht_master"
            >
              <validation-provider
                #default="{ errors }"
                name="price_ht_master"
                rules="required"
              >
                <b-form-input
                  v-model="grade.price_ht_master"
                  :state="errors.length > 0 ? false : null"
                  placeholder="price_ht_master"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addGrade"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      grade: {
        label: '',
        price_ht_license: '',
        price_ht_engineer: '',
        price_ht_master: '',

      },
      required,

    }
  },

  methods: {

    async addGrade() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/grades/create/',
                {
                  label: this.grade.label,
                  price_ht_license: this.grade.price_ht_license,
                  price_ht_engineer: this.grade.price_ht_engineer,
                  price_ht_master: this.grade.master,
                },
              )
            this.$router.push('/grades')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Grade ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
